import React, { useState } from "react";
import { Box, jsx } from "theme-ui";
import {
  Flex,
  Visibility,
  Link,
  Text,
  Heading,
  Image,
  Button,
  TextInput,
  Container,
} from "../core";
import {
  Logo,
  Facebook,
  Instagram,
  Like,
  Check,
  Twitter,
  Linkedin,
  TikTok,
} from "../svg";
import { motion } from "framer-motion";
import useThemeColor from "../../hooks/useThemeColor";
import { useStaticQuery, graphql } from "gatsby";
import Loader from "react-loader-spinner";
import addToMailchimp from "gatsby-plugin-mailchimp";
/** @jsx jsx */

export default function Footer() {
  const { site } = useStaticQuery(seoQuery);
  const [newsletterState, setNewsletterState] = useState("INITIAL");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");

  const getStateNewsletter = () => {
    switch (newsletterState) {
      case "INITIAL":
        return "S'inscrire";
      case "SENDING":
        return (
          <Loader
            type="ThreeDots"
            color="white"
            height={15}
            width={35}
            style={{ position: "relative", top: "2px" }}
          />
        );
      case "SENT":
        return <Check />;
      default:
        return "ERREUR";
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setNewsletterState("SENDING");
    addToMailchimp(email, {
      FNAME: firstName,
    }).then(({ result, msg }) => {
      if (
        result === "success" ||
        (result === "error" && msg.includes("is already subscribed"))
      ) {
        setNewsletterState("SENT");
      } else {
        setNewsletterState("ERROR");
      }
    });
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "secondary",
          py: 7,
          px: 3,
        }}
      >
        <Container>
          <Flex direction="column" gap={5}>
            <Box sx={{ textAlign: "center" }}>
              <Heading as="h2" sx={{ color: "white", marginBottom: 1 }}>
                Newsletter
              </Heading>
              <Text color="white">
                Une fois par mois, on te donne toutes les infos sur l’asso !
              </Text>
            </Box>

            <form
              name="newsletter"
              style={{ height: "100%", width: "100%", maxWidth: "650px" }}
              onSubmit={handleRegister}
            >
              <Flex direction={["column", "row", null]} gap={3}>
                <TextInput
                  variant="newsletter"
                  name="prenom"
                  placeholder="Prénom"
                  value={firstName}
                  onChange={(value) => setFirstName(value)}
                  required
                  style={{
                    maxWidth: ["350px", "170px", null],
                  }}
                />
                <TextInput
                  variant="newsletter"
                  name="newsletter"
                  value={email}
                  type="email"
                  placeholder="E-mail"
                  onChange={(value) => setEmail(value)}
                  required
                  style={{
                    maxWidth: "350px",
                    flexGrow: 1,
                  }}
                >
                  <Image src="/img/envelope.svg" />
                  <TextInput.Field />
                </TextInput>
                <Button
                  sx={{
                    width: "100%",
                    maxWidth: ["350px", "160px", null],
                    height: "50px",
                    textTransform: "uppercase",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                  type="submit"
                  disabled={newsletterState !== "INITIAL"}
                >
                  {getStateNewsletter()}
                </Button>
              </Flex>
            </form>
          </Flex>
        </Container>
      </Box>
      <Box
        as="footer"
        sx={{
          backgroundColor: "#EDF0F2",
          paddingTop: 7,
          paddingBottom: 2,
          px: 3,
        }}
      >
        <Flex
          direction={["column", null, "row"]}
          positions={[
            ["center", "flex-start"],
            null,
            ["space-around", "flex-start"],
          ]}
          gap={["30px", null, "20px"]}
          sx={{
            paddingBottom: 7,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Logo colorTop="primary" colorBottom="secondary" height={73} />
          </Box>
          <Flex
            direction="column"
            positions={[
              ["center", "flex-start"],
              null,
              ["flex-start", "flex-start"],
            ]}
            gap={2}
          >
            <StyledLink to="/qui-sommes-nous">Qui sommes-nous ?</StyledLink>
            <StyledLink to="/evenements">Ramassages</StyledLink>
            <StyledLink to="/sensibilisations">Sensibilisations</StyledLink>
            <StyledLink to="/partenaires">Partenaires</StyledLink>
            <StyledLink to="/contact">Contact</StyledLink>
            {/*<StyledLink to="/boutique">Boutique</StyledLink>*/}
            <StyledLink to="/presse">Presse</StyledLink>
            <StyledLink to="/nous-aider">Nous aider</StyledLink>
            <StyledLink to="/politique-de-confidentialite">
              Politique de confidentialité
            </StyledLink>
          </Flex>
          <Box>
            <Visibility display={["hidden", null, "visible"]}>
              <Heading
                color="secondary"
                uppercase
                fontWeight="bold"
                fontSize={1}
                sx={{ marginBottom: 1 }}
              >
                Réseaux sociaux
              </Heading>
              <Text fontSize={1} sx={{ marginBottom: 2 }}>
                Suis-nous sur les réseaux sociaux pour te tenir informé•e
              </Text>
            </Visibility>
            <Flex gap="26px" positions={["flex-start", "flex-start"]}>
              <StyledLink
                aria-label="Lien facebook"
                to={site.siteMetadata.social.facebook}
                external
                sx={{
                  display: "inline-flex",
                  alignContent: "center",
                }}
              >
                <Facebook color="secondary" colorHover="primary" />
              </StyledLink>
              <StyledLink
                aria-label="Lien instagram"
                to={site.siteMetadata.social.instagram}
                external
                sx={{
                  display: "inline-flex",
                  alignContent: "center",
                }}
              >
                <Instagram color="secondary" colorHover="primary" />
              </StyledLink>
              <StyledLink
                aria-label="Lien twitter"
                to={site.siteMetadata.social.twitter}
                external
                sx={{
                  display: "inline-flex",
                  alignContent: "center",
                }}
              >
                <Twitter color="secondary" colorHover="primary" />
              </StyledLink>
              <StyledLink
                aria-label="Lien linkedin"
                to={site.siteMetadata.social.linkedin}
                external
                sx={{
                  display: "inline-flex",
                  alignContent: "center",
                }}
              >
                <Linkedin color="secondary" colorHover="primary" />
              </StyledLink>
              <StyledLink
                aria-label="Lien tiktok"
                to={site.siteMetadata.social.tiktok}
                external
                sx={{
                  display: "inline-flex",
                  alignContent: "center",
                }}
              >
                <TikTok color="secondary" colorHover="primary" />
              </StyledLink>
            </Flex>
          </Box>
          <Box sx={{ textAlign: ["center", null, "left"] }}>
            <Link
              aria-label="Lien nous aider"
              to="/donation"
              sx={{ display: "flex", marginBottom: 2 }}
            >
              <Image src="/img/asso-interet.svg" />
            </Link>
            <Image src="/img/impact_2024.png" />
          </Box>
        </Flex>
        <Flex
          direction={["column", null, "row"]}
          positions={[["center", "center"], null, ["space-between", "center"]]}
          gap="20px"
          sx={{
            borderTop: "1px solid #DCE4E8",
            paddingTop: 3,
          }}
        >
          <Text color="#889AA2" fontWeight="bold" fontSize={1}>
            © Copyright 2021 | Clean My Calanques | Tous droits réservés.
          </Text>
          <Box>
            <Flex
              direction={["column", "row", null]}
              positions={[["center", "center"], ["flex-end", "baseline"], null]}
            >
              <Flex positions={["flex-end", "baseline"]}>
                <Text color="#889AA2" fontSize={1}>
                  Site réalisé avec
                </Text>
                <Like sx={{ mx: "5px" }} />{" "}
                <Text color="#889AA2" fontSize={1}>
                  par
                </Text>
                <StyledLink
                  href="https://www.linkedin.com/in/katiaegea/"
                  external
                  sx={{
                    my: 0,
                    mx: "4px",
                  }}
                >
                  Katia
                </StyledLink>
                <Text color="#889AA2" fontSize={1}>
                  &
                </Text>
                <StyledLink
                  href="https://www.linkedin.com/in/alexisregnaud/"
                  external
                  sx={{
                    my: 0,
                    mx: "4px",
                  }}
                >
                  Alexis
                </StyledLink>
              </Flex>
              <Visibility display={["hidden", "visible", null]}>
                <Text color="#889AA2" fontSize={1} sx={{ marginRight: "4px" }}>
                  -
                </Text>
              </Visibility>
              <Flex positions={["flex-end", "baseline"]}>
                <Text color="#889AA2" fontSize={1}>
                  Crédits photos :{" "}
                </Text>
                <StyledLink
                  href="https://www.evandesousa.com"
                  external
                  sx={{ margin: 0, marginLeft: "4px" }}
                >
                  Evan de Sousa
                </StyledLink>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

const StyledLink = ({ sx, children, ...props }) => {
  const colorSecondary = useThemeColor("secondary");
  const color = useThemeColor("primary");
  return (
    <Link
      sx={{
        fontWeight: "bold",
        ...sx,
      }}
      variant="footer"
      {...props}
    >
      <motion.span
        initial={{
          color: colorSecondary,
        }}
        whileHover={{
          color,
        }}
        transition={{
          duration: 0.2,
        }}
      >
        {children}
      </motion.span>
    </Link>
  );
};

const seoQuery = graphql`
  query MetaDataFooter {
    site {
      siteMetadata {
        social {
          instagram
          facebook
          tiktok
          twitter
          linkedin
        }
      }
    }
  }
`;
