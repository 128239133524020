import React, { useEffect, useRef, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { jsx, useThemeUI } from "theme-ui";
/** @jsx jsx */
import { useBreakpointIndex } from "../../hooks/breakpoints";
import { useCycle } from "framer-motion";
import NavMobile from "./NavMobile";
import { firstLoad } from "../../helpers/load";
import disableScroll from "disable-scroll";
import SEO from "../SEO";
import CookieBanner from "../CookieBanner";
import { CookiesProvider } from "react-cookie";

const Loader = React.lazy(() => import("./Loader"));

const Layout = ({ children, location }) => {
  const indexBreakpoint = useBreakpointIndex();
  const [isSidebarOpen, toggleSidebarOpen] = useCycle(false, true);
  const isSSR = typeof window === "undefined";
  const [hasOverlay, setHasOverlay] = useState(true);
  const { theme } = useThemeUI();

  useEffect(() => {
    if (isSidebarOpen) {
      disableScroll.on();
    } else {
      disableScroll.off();
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    if (indexBreakpoint > 1 && isSidebarOpen) {
      toggleSidebarOpen();
    }
  }, [indexBreakpoint, isSidebarOpen]);

  useEffect(() => {
    firstLoad().remove();
  }, []);

  return (
    <>
      <SEO />
      <CookiesProvider>
        <NavMobile
          isSidebarOpen={isSidebarOpen}
          toggleSidebarOpen={toggleSidebarOpen}
        />
        <Header
          isDynamic={location.pathname === "/" && indexBreakpoint > 1}
          toggleSidebarOpen={toggleSidebarOpen}
          isSidebarOpen={isSidebarOpen}
        />
        {hasOverlay && (
          <div
            style={{
              backgroundColor: theme.colors.white,
              position: "absolute",
              height: "100%",
              width: "100%",
              zIndex: 899,
            }}
          />
        )}
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <Loader onComplete={() => setHasOverlay(false)} />
          </React.Suspense>
        )}
        {children}
        <Footer />
        <CookieBanner />
      </CookiesProvider>
    </>
  );
};

export default Layout;
